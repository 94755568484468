<template>
  <div class="CategoryTypeList">
    <!-- No grouped types to show -->
    <div
      v-if="!groupedTypes.length"
      class="EmptyList">
      <span>{{ mixWB('NOTHING_ADDED_YET') }}</span>
    </div>

    <!-- Grouped types to show -->
    <div
      v-for="group in groupedTypes"
      :key="group.groupID"
      class="GroupedTypes"
      :class="groupClasses">
      <CategoryTypeListItem
        v-for="(typeItem, index) in group.typeItems"
        :key="typeItem.type.id"
        :type="typeItem.type"
        :cantBeUsed="!!typeItem.cantBeUsed"
        :clickToEdit="!overlayData"
        :outerInterConnection="typeItem.outerInterConnection || false"
        :interConnectionGroupID="group.groupID"
        :isFirstInstance="typeItem.isFirstInstance"
        :isFirstInGroup="index === 0"
        :isLastInGroup="index === group.typeItems.length - 1"
        :isCurrentGroup="group.options.isCurrentGroup"
        :showInnerSelect="!!overlayData.showInnerSelect"
        :showOuterSelect="!!overlayData.showOuterSelect" />
      <div
        class="GroupAmount"
        v-if="!overlayData">
        <span v-if="getShowGroupAreaStatus(group)">
          {{ mixWB('GROUP_AREA') }}: {{ getGroupAreaText(group.groupID) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoryTypeListItem from '@/components/ScreeningItems/CategoryTypeListItem.vue'
import { screeningGroupedTypes } from '@/globals/javascript/_util/grouped-types'

export default {
  name: 'CategoryTypeList',
  props: {
    categoryID: {
      type: String,
      required: true,
    },
    overlayData: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'screeningInterConnections',
      'currentScreeningSelectedFilterUnitID',
      'currentScreeningData',
    ]),
    groupClasses() {
      return {
        ShowOuterSelect: this.overlayData?.showOuterSelect,
        ShowInnerSelect: this.overlayData?.showInnerSelect,
      }
    },
    allInterConnections() {
      return this.overlayData?.allInterConnections ?? this.screeningInterConnections
    },
    allScreeningTypes() {
      return this.overlayData?.allScreeningTypes ?? this.screeningTypes
    },
    groupedTypes() {
      return screeningGroupedTypes({
        interConnections: this.allInterConnections,
        screeningTypes: this.allScreeningTypes,
        categoryID: this.categoryID,
        unitID: this.currentScreeningSelectedFilterUnitID,
        overlayData: this.overlayData,
      })
    },
  },
  methods: {
    getShowGroupAreaStatus(group) {
      if (!group.options.isGroup || !group.options.isAmountRequired) {
        return false
      }

      const groupAmount = this.currentScreeningData.interConnectionGroupAmounts.find(
        (x) => x.id === group.groupID,
      )

      if (this.currentScreeningData.isSkipAmount && !groupAmount?.m2) {
        return false
      }

      return true
    },
    getGroupAreaText(groupID) {
      const groupAmount = this.currentScreeningData.interConnectionGroupAmounts.find(
        (x) => x.id === groupID,
      )

      if (groupAmount?.m2) {
        return `${ groupAmount?.m2 } ${ this.mixWB('M2') }`
      }

      return this.mixWB('NO_AMOUNT_TEXT')
    },
  },
  components: {
    CategoryTypeListItem,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeList
    display block

  .EmptyList
    margin-bottom 20px
    span
      font-style italic
      color $color_grey

  .GroupedTypes
    position relative
    margin-bottom 15px
    box-shadow 1px 2px 3px rgba(0, 0, 0, 0.2)
    &.ShowOuterSelect
      margin-top 30px
      margin-bottom 0
    &.ShowInnerSelect
      margin-bottom 30px
    > div
      border-bottom 1px solid $color_grey_lighter
      &:last-child
        border-bottom none
    .GroupAmount
      background-color $color_grey_lightest
      span
        color $color_grey
        font-size 0.75rem
        line-height 1
        padding 5px 5px
    +below($tablet)
      margin-left -5px
      margin-right -5px
</style>
