<template>
  <div class="AddedTypeMenu">
    <DotMenuItem
      :text="mixWB('ADD_ANALYSES')"
      :isDisabled="isAddAnalysesDisabled"
      itemID="add-samples"
      @dot-item-click="onDotItemClick" />
    <DotSplitLine />
    <DotMenuItem
      :text="mixWB('DELETE_TYPE')"
      itemID="delete"
      color="red"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'
import DotSplitLine from '@/components/DotMenu/DotSplitLine.vue'

export default {
  name: 'AddedTypeMenu',
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAddAnalysesDisabled() {
      return !!this.type.type.customTypeName && !this.type.coating.samples.sampleIDs.length
    },
  },
  methods: {
    onDotItemClick(itemID) {
      if (itemID === 'add-samples') {
        this.$emit('pass-to-parent', 'add-samples-click')
      }
      if (itemID === 'delete') {
        this.$emit('pass-to-parent', 'delete-type-click')
      }
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
    DotSplitLine,
  },
}
</script>

<style lang="stylus" scoped>
</style>
