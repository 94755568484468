<template>
  <div
    class="CategoryTypeListItem"
    :class="rootClasses"
    @click="onItemClick">
    <div class="ImageWrap">
      <MultiImage
        v-if="type.images[0]"
        :image="type.images[0]"
        transformation="square_200" />
      <div
        v-if="!isFirstInstance"
        class="CopyWrap">
        <span>{{ mixWB('COPY') }}</span>
      </div>
    </div>

    <div class="InfoWrap">
      <span
        v-if="isConnectedType"
        class="Title">{{ type.getTitle({ category: true, details: true }) }}</span>
      <span
        v-else
        class="Title">{{ type.getTitle({details: true}) }}</span>
      <div class="Row">
        <div class="IconWrap">
          <HouseIcon />
        </div>
        <span>{{ type.getUnitsList() }}</span>
      </div>
      <div
        class="Row"
        v-if="type.getPlacesList()">
        <div class="IconWrap SmallerIcon">
          <RoomsIcon />
        </div>
        <span>{{ type.getPlacesList() }}</span>
      </div>
      <div
        class="Row"
        v-if="getMaterialSamplesText()">
        <div class="IconWrap SmallerIcon">
          <VialIcon />
        </div>
        <span v-html="getMaterialSamplesText()"/>
      </div>
      <div
        class="Row"
        v-if="getAutoAssessmentsText()">
        <div class="IconWrap SmallerIcon">
          <WarningIcon />
        </div>
        <span>{{ getAutoAssessmentsText() }}</span>
      </div>
      <div
        class="Row"
        v-if="getCoatingSamplesText()">
        <div class="IconWrap SmallerIcon">
          <BrushIcon />
        </div>
        <span v-html="getCoatingSamplesText()"/>
      </div>
      <div
        class="Row"
        v-if="type.dust.samples.manualSampleIDs.length">
        <div class="IconWrap SmallerIcon">
          <TapeIcon />
        </div>
        <span v-html="getDustSamplesText()"/>
      </div>
      <div
        class="Row"
        v-if="type.getAmount()">
        <div class="IconWrap SmallerIcon">
          <AmountIcon />
        </div>
        <span>{{ type.getAmount() }}</span>
      </div>
      <div
        class="Row"
        v-if="type.comments">
        <div class="IconWrap SmallerIcon">
          <NoteIcon />
        </div>
        <span>{{ type.comments }}</span>
      </div>
    </div>

    <div
      v-if="clickToEdit"
      class="DotMenuWrap">
      <DotMenu
        :use-component="AddedTypeMenu"
        :dataToComponent="{
          type,
        }"
        position="normal"
        @add-samples-click="onAddSamplesClick()"
        @delete-type-click="onDeleteTypeClick()" />
    </div>

    <!-- Outer inter connection -->
    <div
      v-if="outerInterConnection && clickToEdit"
      class="InterConnectionIconsWrap"
      @click.stop>
      <InterConnectionIcons
        :interConnection="outerInterConnection"
        :type="type" />
    </div>

    <!-- Inner selectors -->
    <div
      v-if="!isCurrentGroup && showInnerSelect && !cantBeUsed"
      class="SelectersWrap Inner"
      :class="{ IsLastInGroup: isLastInGroup, IsFirstInGroup: isFirstInGroup }">
      <div
        v-if="showSingleTypeOption"
        class="SelectIcon"
        @click="onSelectClick('inner', 'single')">
        <div class="IconWrap Inner Single">
          <SingleTypeIcon />
        </div>
      </div>
      <div
        v-if="!isFirstInGroup"
        class="SelectIcon"
        @click="onSelectClick('inner', 'all')">
        <div class="IconWrap Inner All">
          <AllTypesIcon />
        </div>
      </div>
    </div>

    <!-- Outer selectors -->
    <div
      v-if="!isCurrentGroup && showOuterSelect && !cantBeUsed"
      class="SelectersWrap Outer"
      :class="{ IsLastInGroup: isLastInGroup, IsFirstInGroup: isFirstInGroup }">
      <div
        v-if="showSingleTypeOption"
        class="SelectIcon"
        @click="onSelectClick('outer', 'single')">
        <div class="IconWrap Outer Single">
          <SingleTypeIcon />
        </div>
      </div>
      <div
        v-if="!isLastInGroup"
        class="SelectIcon"
        @click="onSelectClick('outer', 'all')">
        <div class="IconWrap Outer All">
          <AllTypesIcon />
        </div>
      </div>
    </div>

    <!-- Add samples dialog -->
    <div @click.stop="">
      <Dialog
        :isShowing="showModal"
        :useComponent="AddSamplesModal"
        :componentProps="modalProps"
        @close="showModal = false" />
    </div>
  </div>
</template>

<script>
import HouseIcon from '@/assets/svg/house.svg?inline'
import RoomsIcon from '@/assets/svg/rooms.svg?inline'
import VialIcon from '@/assets/svg/vial.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import BrushIcon from '@/assets/svg/brush.svg?inline'
import TapeIcon from '@/assets/svg/tape.svg?inline'
import NoteIcon from '@/assets/svg/edit.svg?inline'
import AmountIcon from '@/assets/svg/amount.svg?inline'
import SingleTypeIcon from '@/assets/svg/single-type.svg?inline'
import AllTypesIcon from '@/assets/svg/all-types.svg?inline'
import MultiImage from '@/components/Images/MultiImage.vue'
import EventBus from '@/EventBus'
import InterConnectionIcons from '@/components/ScreeningItems/InterConnectionIcons.vue'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import AddedTypeMenu from '@/components/ScreeningItems/Menus/AddedTypeMenu.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import AddSamplesModal from '@/components/Modals/AddSamplesModal/AddSamplesModal.vue'

export default {
  name: 'CategoryTypeListItem',
  props: {
    type: {
      type: Object,
      required: true,
    },
    cantBeUsed: {
      type: Boolean,
      required: false,
      default: false,
    },
    clickToEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    outerInterConnection: {
      type: [Object, Boolean],
      required: true,
      default: false,
    },
    interConnectionGroupID: {
      type: String,
      required: true,
    },
    isFirstInstance: {
      type: Boolean,
      required: true,
    },
    isFirstInGroup: {
      type: Boolean,
      required: true,
    },
    isLastInGroup: {
      type: Boolean,
      required: true,
    },
    isCurrentGroup: {
      type: Boolean,
      required: true,
    },
    showInnerSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOuterSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    isConnectedType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      AddedTypeMenu,
      // Dialog
      showModal: false,
      AddSamplesModal,
      modalProps: {
        type: {},
        showAllSampleTypes: true,
      },
    }
  },
  computed: {
    rootClasses() {
      return {
        ClickToEdit: this.clickToEdit,
        IsCurrentGroup: this.isCurrentGroup,
      }
    },
    showSingleTypeOption() {
      const resourceType = this.type.getResourceType()
      if (!resourceType?.options?.isNeverAlone) {
        return true
      }

      if (this.isLastInGroup && this.isFirstInGroup) {
        return true
      }

      // Inner select
      if (this.showInnerSelect && this.isLastInGroup) {
        return false
      }

      // Outer select
      if (this.showOuterSelect && this.isFirstInGroup) {
        return false
      }

      return true
    },
  },
  methods: {
    getMaterialSamplesText() {
      const materialSampleItems = this.type.getMaterialSampleList({ asArray: true })

      return materialSampleItems.reduce((prev, item) => {
        if (item.isManual) {
          if (!prev) {
            prev = `<b>${ item.title }</b>`
          }
          else {
            prev += `, <b>${ item.title }</b>`
          }
        }
        else if (!prev) {
          prev = item.title
        }
        else {
          prev += `, ${ item.title }`
        }
        return prev
      }, '')
    },
    getCoatingSamplesText() {
      const coatingSampleItems = this.type.getCoatingSampleList({ asArray: true })

      return coatingSampleItems.reduce((prev, item) => {
        if (item.isManual) {
          if (!prev) {
            prev = `<b>${ item.title }</b>`
          }
          else {
            prev += `, <b>${ item.title }</b>`
          }
        }
        else if (!prev) {
          prev = item.title
        }
        else {
          prev += `, ${ item.title }`
        }
        return prev
      }, '')
    },
    getDustSamplesText() {
      const dustSampleItems = this.type.getDustSampleList({ asArray: true })

      return dustSampleItems.reduce((prev, item) => {
        if (!prev) {
          prev = `<b>${ item.title }</b>`
        }
        else {
          prev += `, <b>${ item.title }</b>`
        }
        return prev
      }, '')
    },
    getAutoAssessmentsText() {
      // Deprecated: Resale logic
      if (this.type.resale === 'yes') {
        return false
      }

      if (!this.type.assessments.sampleIDs.length) {
        return false
      }

      return `${
        this.type.assessments.sampleIDs.length
      } ${
        this.type.assessments.sampleIDs.length === 1
          ? this.mixWB('ASSESSMENT').toLowerCase()
          : this.mixWB('ASSESSMENTS').toLowerCase()
      } (${
        this.type.getAssessmentList()
      })`
    },
    onItemClick() {
      if (!this.clickToEdit) {
        return
      }

      this.$router.push({
        name: 'ScreeningsUpdateCategoryEditType',
        params: { categoryID: this.type.categoryID, addedTypeID: this.type.id },
      })
    },
    onAddSamplesClick() {
      this.modalProps.type = this.type
      this.showModal = true
    },
    onDeleteTypeClick() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      // Delete type
      this.$store.dispatch('deleteAddedType', this.type.id)
    },
    onSelectClick(side, choice) {
      EventBus.$emit('inter-connection-select', {
        side,
        choice,
        interConnectionGroupID: this.interConnectionGroupID.includes('single-')
          ? ''
          : this.interConnectionGroupID,
        typeID: this.type.id,
        isFirstInGroup: this.isFirstInGroup,
        isLastInGroup: this.isLastInGroup,
      })
    },
  },
  components: {
    HouseIcon,
    RoomsIcon,
    VialIcon,
    WarningIcon,
    BrushIcon,
    TapeIcon,
    NoteIcon,
    AmountIcon,
    SingleTypeIcon,
    AllTypesIcon,
    MultiImage,
    InterConnectionIcons,
    DotMenu,
    Dialog,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryTypeListItem
    position relative
    background-color $color_grey_lightest
    padding 5px
    padding-right 0
    display flex
    &.IsCurrentGroup
      background-color $color_primary_lightest
    &.ClickToEdit
      cursor pointer

  .ImageWrap
    position relative
    flex-shrink 0
    box(94px)
    margin-right 5px
    .CopyWrap
      position absolute
      left 0
      bottom 0
      height 16px
      width 100%
      background-color $color_grey
      flex-center-children()
      span
        font-size 0.75rem
        text-align center
        color #fff

  .InfoWrap
    flex-grow 2
    min-width 1px
    span
      truncated()
    .Title
      font-size 0.875rem
      text-transform uppercase
      color $color_grey_dark
    .Row
      display flex
      align-items center
      margin-bottom 2px
      &:last-child
        margin-bottom 0
      .IconWrap
        box(14px)
        flex-center-children()
        margin-right 5px
        flex-shrink 0
        svg
          fill $color_grey
          flex-shrink 0
        &.SmallerIcon
          padding 1px
      span
        font-size 0.75rem
        padding-top 2px
        height 14px
        line-height 1
        b
          color red

  .DotMenuWrap
    flex-center-children()
    flex-shrink 0

  .NoteWrap
    padding-top 5px
    span
      truncated()
      font-size 0.75rem
      height 15px
      margin-bottom 2px

  .InterConnectionIconsWrap
    position absolute
    z-index 1
    bottom -16px
    left 8px

  .SelectersWrap
    position absolute
    z-index 1
    flex-center-children-column()
    justify-content center
    box(40px, 80px)
    &.Inner
      right -15px
      bottom calc(50% - 40px)
      &.IsLastInGroup
        flex-direction row
        box(80px, 40px)
        bottom -20px
        left calc(50% - 40px)
    &.Outer
      left -15px
      top calc(50% - 40px)
      &.IsFirstInGroup
        flex-direction row
        box(80px, 40px)
        top -20px
        left calc(50% - 40px)
    .SelectIcon
      box(40px)
      padding 5px
      cursor pointer
      .IconWrap
        circle(30px)
        background-color $color_primary
        overflow hidden
        svg
          fill #fff
        &.Inner
          svg
            transform rotateZ(180deg)
          &.Single
            svg
              margin-top -3px
        &.Outer
          &.Single
            svg
              margin-top 3px

</style>

<style lang="stylus">
  .CategoryTypeListItem
    .Row
      b
        line-height 1
        color $color_purple_dark
</style>
